<template>
  <div>
    <v-tabs class="mb-4" next>
      <v-tab v-for="(tab, i) in this.tabs" :to="tab.url" :key="i">
        <span class="text">{{ tab.heading }}</span>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { url: '/stats/domain/date/' + this.$route.params.domain, heading: this.$t('stats_domain_date.heading') },
        { url: '/stats/domain/week/' + this.$route.params.domain, heading: this.$t('stats_domain_week.heading') },
        { url: '/stats/domain/month/' + this.$route.params.domain, heading: this.$t('stats_domain_month.heading') },
        { url: '/stats/domain/year/' + this.$route.params.domain, heading: this.$t('stats_domain_year.heading') }
      ]
    }
  }
}
</script>
