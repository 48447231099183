<template>
  <div id="stats-domain-index">
    <v-row no-gutters>
      <v-col class="col-md-10">
        <Headline :text="$t('stats_domain.heading') + ' ' + $route.params.domain" />
      </v-col>
      <v-col class="col-md-2 text-right">
        <PrimaryButton url="/domains_add">{{ $t('domain_index.add_domains') }}</PrimaryButton>
      </v-col>
    </v-row>
    <StatsDomain />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import PrimaryButton from '@/components/app/PrimaryButton.vue'
import StatsDomain from '../../components/stats/StatsDomain'

export default {
  components: {
    Headline,
    PrimaryButton,
    StatsDomain
  }
}
</script>
